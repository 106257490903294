import React from 'react';
import './SearchResults.scss';

export default function SearchResults({ data }) {
    if (!data) {
        return <></>;
    }

    console.log(data)

    return (
        <div>
            <div className="_restaurant-list _search-results-numbering">
                {data.total > 0 && <p>Showing {data.from}-{data.to} of {data.total} results</p>}
            </div>
        </div>
    );
}
