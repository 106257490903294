import React from 'react'

export default function RestaurantItem({ restaurant }) {

  const renderCultivars = () => {
    const cultivars = restaurant.available_wines.split(', ')
    return cultivars.map((item, index) => {
      return <span key={index} className='_cultivar-tag'>{item}</span>
    })
  }

  return (
    <div className='_restaurant-item'>
      <div className='_heading-container'>
        <h2>{restaurant.title}</h2>
        <div className='_buttons'>
          {restaurant.booking_link && restaurant.booking_link !== 'NULL' && (
            <a href={restaurant.booking_link} className='btn _book-here'>BOOK HERE</a>
          )}
          {restaurant.share_link && restaurant.share_link !== 'NULL' && (
            <a href={restaurant.share_link} className='btn _share'>SHARE</a>
          )}
        </div>
      </div>
      <div className='_item-content'>
        {restaurant.website_url && restaurant.website_url !== 'NULL' && (
          <p><a href={restaurant.website_url} className='_purple-underline' target="_blank" rel="noreferrer">{restaurant.website_url}</a></p>
        )}
        <p>
          {restaurant.contact_person_name && restaurant.contact_person_name !== 'NULL' && (
            <>{restaurant.contact_person_name}<span className='_divider' /></>
          )}
          {restaurant.phone && restaurant.phone !== 'NULL' && (
            <><a href={`tel:${restaurant.phone}`}>{restaurant.phone}</a></>
          )}
          {restaurant.email && restaurant.email !== '' && (
            <><span className='_divider' /><a href={`mailto:${restaurant.email}`}>{restaurant.email}</a></>
          )}
        </p>
        <p>
          {restaurant.location_pin && restaurant.location_pin !== '' && restaurant.location_pin !== 'NULL' && (
            <>
              <a href={restaurant.location_pin} className='_purple-underline' target="_blank" rel="noreferrer">
                {restaurant.location_address && restaurant.location_address !== '' && restaurant.location_address !== 'NULL' ? restaurant.location_address : restaurant.location_pin}
              </a>
              <span className='_divider' />
            </>
          )}
          {restaurant.province && restaurant.province !== 'NULL' && restaurant.province }
        </p>
        <p>
          {restaurant.facebook_url && restaurant.facebook_url !== '' && (
            <a href={restaurant.facebook_url} className='_purple-underline' target="_blank" rel="noreferrer">Facebook</a>
          )}
          {restaurant.instagram_url && restaurant.instagram_url !== '' && (
            <><span className='_divider' /><a href={restaurant.instagram_url} className='_purple-underline' target="_blank" rel="noreferrer">Instagram</a></>
          )}
        </p>
        {
          restaurant.available_wines && restaurant.available_wines !== '' && restaurant.available_wines !== 'NULL' && (
            <>
              <br />
              <p>Wines Available on MENU</p>
              <div className='_wines-avail'>
                {renderCultivars()}
              </div>
            </>
          )
        }
        
      </div>
      
      
      
    </div>
  )
}
