import axios from "axios";

export const fatBastard = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v2",
  baseURL: "https://admin.fatbastard.stageoptimal.co.za/api/v2",
  headers: {
    Authorization: "Bearer AWY97FKHW2",
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// export const fatBastard = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v2",
//   headers: {
//     Authorization: "Bearer AWY97FKHW2",
//     Accept: "application/json",
//     "Content-Type": "application/json"
//   }
// });

export const world = axios.create({
  Authorization: "Bearer AWY97FKHW2",
  baseURL: "https://world.stg7.optimalonline.co.za/api/v2",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export const register = axios.create({
  Authorization: "Bearer ",
  baseURL: "https://api-node-v2.stg7.optimalonline.co.za/api/v2",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export const campaign = axios.create({
  // baseURL: 'http://127.0.0.1:8000/api'
  baseURL: 'https://campaign.optimalonline.co/api'
})